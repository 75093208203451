<template>
  <v-container class="pa-2">
    <!-- switch for newest / published sorting  -->
    <v-container>
      <span class="my-auto"> sort by: </span>
      <v-chip
        :class="sortedBy == 'date' ? '' : 'outlined'"
        @click="sortBy('date')"
      >
        article date
        <v-icon right
          v-if="sortedBy == 'date' && sortingOrder"
        > 
          mdi-chevron-up 
        </v-icon>
        <v-icon right
          v-else-if="sortedBy == 'date'"
        > 
          mdi-chevron-down 
        </v-icon>
      </v-chip> | 
      <v-chip
        :class="sortedBy == 'publish' ? '' : 'outlined'"
        @click="sortBy('publish')"
      >
        published date
        <v-icon right
          v-if="sortedBy == 'publish' && sortingOrder"
        > 
          mdi-chevron-up 
        </v-icon>
        <v-icon right
          v-else-if="sortedBy == 'publish'"
        > 
          mdi-chevron-down 
        </v-icon>
      </v-chip>
    </v-container>

    <!-- Navigation top of page -->
    <v-row align="center">
      <v-col cols = "3">
        <v-btn
          :large="$vuetify.breakpoint.mdAndUp"
          v-if="page !== 1"
          class="ml-0"
          square
          title="Previous page"
          @click="page--">
        <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </v-col>

      <v-col
        class="text-center subheading"
        cols="6">
        PAGE {{ page }} OF {{ pages }}
      </v-col>

      <v-col
        class = "text-right"
        ncols="3">
        <v-btn
          v-if="pages>1 && page < pages"
          class="mr-0"
          square
          :large="$vuetify.breakpoint.mdAndUp"
          title="Next page"
          @click="page++">
        <v-icon>mdi-chevron-right</v-icon>
        </v-btn>        

      </v-col>
    </v-row>
    <!-- content -->
    <v-row>

      <feed-card
      v-for = "(a,i) in paginatedArticles"
      :key="a.key"
      :cardsize="layout[i]"
      :value="a"
      />
    </v-row>
    
    <!-- Navigation bottom of page -->
    <v-row align="center">
      <v-col cols = "3">
        <v-btn
          :large="$vuetify.breakpoint.mdAndUp"
          v-if="page !== 1"
          class="ml-0"
          square
          title="Previous page"
          @click="page--">
        <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </v-col>

      <v-col
        class="text-center subheading"
        cols="6">
        PAGE {{ page }} OF {{ pages }}
      </v-col>

      <v-col
        class = "text-right"
        ncols="3">
        <v-btn
          v-if="pages>1 && page < pages"
          class="mr-0"
          square
          :large="$vuetify.breakpoint.mdAndUp"
          title="Next page"
          @click="page++">
        <v-icon>mdi-chevron-right</v-icon>
        </v-btn>        

      </v-col>
    </v-row>

  </v-container>

</template>

<script>
  import { mapState,mapGetters } from 'vuex'
  export default {
    name: 'Articles',

    data: () => ({
      layout: [3,3,3,2,2,2,2,3,3,3],//[3, 2, 2, 1, 2, 2, 3, 3, 3, 3, 3, 3],
      page: 1,
      sortedBy: 'date',
      sortingOrder: true,    // false = top to bottom, true = bottom up
      // allArticlesList: [],
    }),
    
    components: {
      FeedCard: () => import('@/components/core/FeedCard'),
    },

    computed: {
      ...mapState(['articles']),
      ...mapGetters(['sortedArticles', 'allArticlesSortedBy']),
      pages () {
        return Math.ceil(this.articles.length / (this.layout.length))
      },
      paginatedArticles() {
        const start = (this.page-1) * (this.layout.length)
        const stop = this.page * (this.layout.length)
        return this.allArticlesList.slice(start, stop)
      },

      allArticlesList() {

        let sortCriteria = this.sortedBy
        if (this.sortingOrder) {
          // when 1 we go upside down!
          sortCriteria = sortCriteria + '-'
        } 
        return this.allArticlesSortedBy(sortCriteria)
      }

    },
    methods: {
      sortBy(criteria) {
        // resets the current sorting list with a sorted one
        if (this.sortedBy == criteria) {
          // sorting-order switches!
          this.sortingOrder = !this.sortingOrder 
        } else {
          this.sortingOrder = false
        }
        this.sortedBy = criteria
        // this.allArticlesList = this.allArticlesSortedBy(sortCriteria)
      }
    },
    watch: {
      page () {
        window.scrollTo(0, 0)
      }
    },
    mounted() {
      this.allArticlesList = this.allArticlesSortedBy(this.sortedBy)
    }
    

    
  }
</script>
